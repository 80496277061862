import { parse, format } from 'date-fns';

const makeDateSeparatorsConsistent = (date: string): string => {
  const regex = /-/g;
  return date.replace(regex, '/');
};

export const replaceSlashesWithHyphens = (date: string): string => {
  const regex = /[\/]/g;
  return date.replace(regex, '-');
};

export const getDateFormat = (date: string): string => {
  if (date) {
    const dateWithExpectedSeparators = makeDateSeparatorsConsistent(date);
    return dateWithExpectedSeparators.split('/')[2].length !== 4 ? 'yyyy/MM/dd' : 'dd/MM/yyyy';
  }
  return date;
};

export const convertDateToDlgFormat = (date: string): Date => {
  const dateWithExpectedSeparators = makeDateSeparatorsConsistent(date);
  return parse(dateWithExpectedSeparators, getDateFormat(dateWithExpectedSeparators), new Date());
};

export const convertDateToDisplayFormat = (date: string): string => {
  const dateWithExpectedSeparators = makeDateSeparatorsConsistent(date);
  const dateObject = parse(dateWithExpectedSeparators, getDateFormat(dateWithExpectedSeparators), new Date());
  return dateObject.toLocaleDateString("en-GB");
};

export const formatDate = (date): string => {
  const dateToReturn = date ? format((new Date(date)), 'yyyy/MM/dd') : '';
  return dateToReturn;
};

export function dateDifference(d0, d1) {
  return Math.round((d1 - d0) / 8.64e7);
}

export function dateIsValid(date) {
  return !Number.isNaN(new Date(date).getTime());
}