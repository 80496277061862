import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import PageTemplate from '../../templates/PageTemplate';
import { DocumentTitle, ButtonPrimary, StepChecker } from '../../components/atoms';
import { StepContext } from '../../contexts/StepContext';
import { useStyles } from './FailedPaymentStyles';

export const FailedPayment: React.FC = () => {
  const { updateShowStepper, updateData, data, updateActiveStep } = useContext(StepContext);
  const classes = useStyles();
  const history = useHistory();

  const returnJourney = async () => {
    updateData({
      ...data,
    });
    updateActiveStep(3);
    updateShowStepper(true);
    history.push('/ImportantInfo');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Processing`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} sm={5} lg={6} className={classes.gridMain}>
          <Typography className={classes.heading} variant="h1">
            PAYMENT FAILED
          </Typography>
          <Typography className="pt1">Sorry, we were unable to take your payment.</Typography>
          <Typography className="pt1 pb3">Please give it another go, as it may be a one-time error.</Typography>
          <ButtonPrimary buttonType="light" onClick={returnJourney}>
            Try again
          </ButtonPrimary>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default FailedPayment;
